import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import InputCheckbox from '@pretto/bricks/components/form/InputCheckbox'
import TextField from '@pretto/bricks/components/form/TextField'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import MarketingBannerTemplate from '@pretto/bricks/website/shared/components/MarketingBannerTemplate'

import PropTypes from 'prop-types'
import { Fragment, memo, useState } from 'react'

import * as S from './styles'

const EbookBanner = ({ onSubscribe, loading, subscribed, standAlone }) => {
  const [email, setEmail] = useState('')
  const [optin, setOptin] = useState(false)
  const handleChangeInput = value => setEmail(value)
  const handleChangeOptin = () => setOptin(!optin)
  const handleSubscribe = e => {
    e.preventDefault()
    onSubscribe(email, optin)
  }

  const bannerProps = {
    coverPath: 'v1553003467/website/guide/ebook_cover.png',
    standAlone,
    title: onSubscribe ? 'Découvrez le guide de l’acheteur' : 'De la recherche du bien au crédit',
  }
  return (
    <MarketingBannerTemplate {...bannerProps}>
      {loading && <SpinnerLegacy overlay />}
      <S.Description>
        <Text variant="white">
          Soyez prêt pour votre premier achat immobilier. Tous nos conseils sont réunis dans un ebook gratuit !
        </Text>
      </S.Description>
      <S.Content>
        {onSubscribe ? (
          <div>
            {!subscribed && (
              <Fragment>
                <S.Form onSubmit={handleSubscribe}>
                  <S.Email>
                    <TextField
                      onChange={handleChangeInput}
                      type="email"
                      value={email}
                      format="rounded"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Votre adresse email"
                      required
                    />
                    <S.Button>
                      <ButtonLegacy type="submit" name="subscribe" format="circle" size="x-small" variant="accent-1">
                        <Text>
                          <Icon name="arrow-right" />
                        </Text>
                      </ButtonLegacy>
                    </S.Button>
                  </S.Email>
                </S.Form>
                <S.Optin>
                  <InputCheckbox
                    id="ebook"
                    isChecked={optin}
                    label={
                      <Text size="small" variant="white">
                        Je souhaite recevoir des conseils sur le prêt immobilier
                      </Text>
                    }
                    onChange={handleChangeOptin}
                  />
                </S.Optin>
              </Fragment>
            )}
            {subscribed && (
              <Card variant="white" zDepth={1}>
                <Content>
                  <SubHeading size="small" type="h4">
                    Merci !
                  </SubHeading>
                  <p>
                    <Text size="small" type="span">
                      Le Guide de l’achat immobilier vous attend dans votre boîte mail.
                    </Text>
                  </p>
                  <p>
                    <Text size="small" type="span">
                      À bientôt !
                    </Text>
                  </p>
                </Content>
              </Card>
            )}
          </div>
        ) : (
          <ButtonLegacy href="https://www.staging.pretto.fr/ressources/ebook/" variant="accent-1">
            Télécharger
          </ButtonLegacy>
        )}
      </S.Content>
    </MarketingBannerTemplate>
  )
}

EbookBanner.propTypes = {
  /** Whether component should be on a loading state. The Spinner is global. */
  loading: PropTypes.bool,
  /** Subscribe function when dispay allows it. */
  onSubscribe: PropTypes.func,
  /** Whether it should be displayed as a full component or an article component. */
  standAlone: PropTypes.bool,
  /** Whether if user has subscribed to the ebook. Display can differ depending on this option. */
  subscribed: PropTypes.bool,
}

export default memo(EbookBanner)
